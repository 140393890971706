import request from "@/utils/request";

/**
 * 获取 我的 线索
 * @param param
 * @returns {*}
 */
export const getLineData = (param) => {
    return request({
        url: '/sys/crmLine/selectPageList',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};
/**
 * Excel导入 线索
 * @param file
 * @returns {AxiosPromise}
 */
export const importCrmLine = (file) => {
    return request({
        url: '/sys/crmLine/importCrmLine',
        method: 'POST',
        data: file
    });
};

/**
 * 添加 线索
 * @param param
 * @returns {*}
 */
export const addLine = (param) => {
    return request({
        url: '/sys/crmLine/addLine',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};

/**
 * 分配 线索
 * @param param
 * @returns {*}
 */
export const allocationLine = (param) => {
    return request({
        url: '/sys/crmLine/allocation',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};

/***
 * 导出数据
 * @param query
 * @returns {AxiosPromise}
 */
export function exportCrmLine(query) {
    return request({
        url: '/sys/crmLine/exportCrmLine',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    })
}