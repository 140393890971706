<template>
  <el-dialog v-model="addThreadDialog" title="添加线索" width="1000px">
    <el-form :model="thread" :rules="threadRules" ref="threadRules">
      <el-row>
        <el-col :span="7">
          <el-form-item label="客户名称" label-width="120px" prop="name">
            <el-input v-model="thread.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="客户电话" label-width="120px" prop="phone">
            <el-input v-model="thread.phone"></el-input>
          </el-form-item>
        </el-col>
        <el-row>
          <el-col :span="7">
            <el-form-item label="客户来源" label-width="120px" prop="source">
              <el-select v-model="thread.source">
                <el-option v-for="(item,i) in sourceList" :key="i" :value="item.dictLabel" :label="item.dictLabel"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="客户类型" label-width="120px" prop="custTypeId">
              <el-select v-model="thread.custTypeId">
                <el-option v-for="(item,i) in custType" :key="i" :value="item.dictValue" :label="item.dictLabel"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="客户地址" label-width="120px" prop="temporaryArea">
              <el-cascader
                  size="large"
                  :options="pcaTextArr"
                  v-model="thread.temporaryArea">
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
      </el-row>
      <div style="height: 100px"></div>
    </el-form>
    <template #footer>
      <el-button @click="addThreadDialog = false">取 消</el-button>
      <el-button @click="add" type="primary">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import {addLine} from '@/api/crmLine.js'
import {selectDictByType} from '@/api/dict.js'
import { pcaTextArr } from 'element-china-area-data'
export default {
  name: "AddThread",
  data() {
    return {
      addThreadDialog:false,
      thread:{
        name:"",
        phone:"",
        source:null,
        custTypeId:null,
        city:null,
        province:null,
        temporaryArea:null
      },
      threadRules: {
        name: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入', trigger: 'blur'},
          { len: 11 , message: '手机号有误！', trigger: 'blur' }
        ],
        source: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
        custTypeId: [
          {required: true, message: '请选择', trigger: 'change'},
        ],
        province: [
          {required: true, message: '请选择', trigger: 'change'},
        ],
      },
      pcaTextArr,
      custType:[],
      sourceList:[],
    }
  },
  created() {
    selectDictByType("custType").then(val =>{
      this.custType = val.data
    })
    selectDictByType("crmSource").then(val =>{
      this.sourceList = val.data
    })
  },
  methods:{
    openDialog(){
      this.addThreadDialog = !this.addThreadDialog
    },
    add(){
      this.$refs.threadRules.validate((valid)=>{
        if(valid){
          this.thread.province = this.thread.temporaryArea[0]
          this.thread.city =this.thread.temporaryArea[1]
          addLine(this.thread).then(val =>{
            this.addThreadDialog = false;
            this.$refs.threadRules.resetFields()//清空表单
            if (val.code == 200){
              this.$message.success('添加成功！');
            }else {
              this.$message.error(val.message);
            }
          })
        }
      })

    }
  }
}
</script>

<style scoped>

</style>