<template>
  <div>
    <div style="padding:20px">
      <!--搜索框-->
      <div style="display:flex;margin-bottom:20px">
        <el-button style="margin-right: 10px" type="primary" @click="openAddThreadDialog">添加</el-button>
        <!--联系人-->
        <el-input style="width: 160px" v-model="query.info" placeholder="联系人/手机号"
                  class="handle-input mr10"></el-input>
        <!--客户类型-->
        <el-select style="width: 160px"
                   class="mr10" placeholder="客户类型" v-model="query.custTypeIdList"
                   clearable
                   multiple
                   collapse-tags
                   collapse-tags-tooltip
        >
          <el-option v-for="(item,i) in custTypeList" :key="i" :value="item.dictValue"
                     :label="item.dictLabel"></el-option>
        </el-select>
        <!--选择部门 -->
        <el-cascader
            class="mr10"
            collapse-tags
            style="width: 160px"
            collapse-tags-tooltip
            clearable
            v-model="query.deptId"
            placeholder="请选择部门"
            :props="{
              multiple: true,
                value: 'id',
                label: 'name',
                children: 'children'}"
            :options="deptList"/>
        <!--客户状态-->
        <el-select placeholder="客户当前状态" style="width: 160px" v-model="query.thisState" class="inPut">
          <el-option v-for="(item,i) in thisStateList" :key="i" :value="item.dictValue"
                     :label="item.dictLabel"></el-option>
        </el-select>
        <el-select placeholder="线索来源" style="width: 160px" v-model="query.source" class="inPut">
          <el-option v-for="(item,i) in sourceList" :key="i" :value="item.dictLabel"
                     :label="item.dictLabel"></el-option>
        </el-select>
        <!--客户状态-->
        <!--        <el-select placeholder="数据类型" multiple style="width: 160px" v-model="query.custTypeIdList" class="inPut">-->
        <!--          <el-option value="招商" label="招商"></el-option>-->
        <!--          <el-option value="线索" label="线索"></el-option>-->
        <!--        </el-select>-->
        <!--所在省市-->
        <el-cascader
            class="mr10"
            style="width: 160px"
            :props="{checkStrictly: true}"
            @change="changeAddress"
            :options="pcaTextArr"
            v-model="address">
        </el-cascader>
        <el-button style="margin-left: 10px" type="primary" @click="searchData">搜索</el-button>
        <el-button type="primary" @click="importData">导入</el-button>
        <el-button type="primary" @click="exportCrmLine">导出</el-button>
      </div>

      <!--列表-->
      <el-table :data="studentLineList" border
                :row-style="{height:2+'px'}"
                :cell-style="{color:'#909399',fontSize:'12.5px',padding:'6px 0px'}"
                :header-cell-style="{background:'#ffff !important;'}"
      >
        <el-table-column label="名称" prop="name"/>
        <el-table-column label="手机号" prop="phone"/>
        <el-table-column label="部门" prop="deptName"/>
        <el-table-column label="创建人" prop="createName"/>
        <el-table-column label="类型" prop="custTypeIdName"/>
        <el-table-column label="来源" show-overflow-tooltip="true" prop="source"/>
        <el-table-column label="跟进人" prop="followUpName"/>
        <el-table-column label="所属地区" show-overflow-tooltip="true">
          <template #default="scope">
            <div>{{ scope.row.province }} - {{ scope.row.city }}</div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" min-width="110"/>
        <el-table-column label="备注说明" prop="remark"/>
        <el-table-column label="类型" prop="crmType"/>
        <el-table-column label="线索状态" prop="thisStateName"/>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="text" @click="editLineCoach(scope.row)">分配</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="current"
            :total="total"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!--导入线索的dialog弹框 -->
    <el-dialog v-model="showImportDialog" title="导入线索" width="600px">
      <div style="text-align: center;">
        <el-upload drag :limit=1 :auto-upload="false" :on-remove="handleRemove"
                   :on-change="fileChange" :on-exceed="exceedFile" accept=".xls , .xlsx">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
    </el-dialog>

    <!--导入线索的dialog弹框 -->
    <el-dialog v-model="showAllocationCrmLineDialog" title="分配线索" width="60%"
               :before-close="handleClose">
      <el-form>
        <el-row>
          <el-col :span="10">
            <el-form-item label="线索接收人" label-width="120px">
              <el-select
                  v-model="editLineCoachForm.coachId"
                  filterable
                  placeholder="请输入或选择人员"
                  remote
                  :remote-method="selectCoach">
                <el-option
                    v-for="item in coachList"
                    :key="item.coachid"
                    :label="item.coachname"
                    :value="item.coachid"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <el-button @click="showAllocationCrmLineDialog=false">
          取消
        </el-button>
        <el-button @click="doEditLineCoach" type="primary">
          确定
        </el-button>
      </template>
    </el-dialog>

    <add-thread ref="addThread"/>
  </div>
</template>

<script>
// 导入需要用到的js模块
import {allocationLine, getLineData, importCrmLine, exportCrmLine} from '@/api/crmLine.js'  //, addLine
import {selectDictByType} from '@/api/dict.js'
import {listXcxDept} from '@/api/dept.js'
import {pcaTextArr} from 'element-china-area-data'
import {listCoach} from "@/api/framework";
// 导入需要使用的子vue组件
import addThread from "./components/AddThread";

export default {
  components: {
    addThread
  },
  name: "CrmLine",
  data() {
    return {
      //搜索条件 表单 传入值
      query: {
        info: '',
        custTypeIdList: [],
        deptId: null,
        thisState: null,
        province: null,
        city: null,
        pageIndex: 1,
        pageSize: 10,
      },
      //分页相关属性值
      studentLineList: null,
      total: null,
      current: null,
      //初始化时就需要加载的数据
      deptList: [],
      custTypeList: [],
      thisStateList: [],
      pcaTextArr: pcaTextArr,
      address: [],
      coachList: [],
      //双向绑定 是否显示 导入的Dialog
      showImportDialog: false,
      //双向绑定 是否显示 线索归属人的Dialog
      showAllocationCrmLineDialog: false,
      //分配线索 所需
      editLineCoachForm: {
        coachId: null,
        crmLines: []
      },
    }
  },
  methods: {
    //加载列表数据
    searchData() {
      if (this.query.deptId) {
        this.query.deptId = [...this.query.deptId.flat()];
      }
      //请求线索列表接口
      getLineData(this.query).then(res => {
        if (res.code === 200) {
          this.studentLineList = res.data.records
          this.total = res.data.total
          this.current = res.data.current
        } else {
          this.$message.error('获取数据失败')
        }
      })
    },
    exportCrmLine() {
      if (this.query.deptId) {
        this.query.deptId = [...this.query.deptId.flat()];
      }
      exportCrmLine(this.query).then(res => {
        console.log(res)
        this.$message.success('正在导出请稍等，右上角查看！');
      })
    },
    openAddThreadDialog() {
      this.$refs.addThread.openDialog();
    },
    // 分页点击触发
    handlePageChange(e) {
      if (this.current !== e) {
        this.query.pageIndex = e
        this.searchData()
      }
    },
    /**
     * 获取 初始化的 等数据
     * @returns {Promise<void>}
     */
    async getInitData() {
      //获取初始的客户来源
      await selectDictByType('crmSource').then(res => {
        if (res.code === 200) {
          this.sourceList = res.data
        } else {
          this.$message.error('获取客户类型失败')
        }
      })
      await selectDictByType('custType').then(res => {
        if (res.code === 200) {
          this.custTypeList = res.data
        } else {
          this.$message.error('获取客户类型失败')
        }
      })
      //获取初始的客户状态
      await selectDictByType('custThisState').then(res => {
        if (res.code === 200) {
          this.thisStateList = res.data
        } else {
          this.$message.error('获取意向客户当前状态失败')
        }
      })
      //加载列表数据
      this.searchData()
      //获取初始的 部门列表
      await listXcxDept().then(res => {
        if (res.code === 200) {
          this.deptList = this.getTreeData(res.data);
        }
      })
      //初始化时传入 空值检索一次教练
      this.selectCoach('');
    },
    //修改地址时
    changeAddress() {
      if (this.address.length >= 2) {
        this.query.province = this.address[0]
        this.query.city = this.address[1]
      } else {
        this.address = []
      }
    },
    //递归取得 树状数据数据 到数组中
    getTreeData(treeDataList) {
      if (treeDataList != null) {
        treeDataList.forEach(item => {
          if (item != null) {
            if (item.children != null && item.children.length > 0) {
              this.getTreeData(item.children);
            } else {
              item.children = undefined
            }
          }
        })
        return treeDataList;
      }
    },
    //导入点击事件
    importData() {
      this.showImportDialog = true
    },
    //导入框中 文件选中后的事件
    async fileChange(e) {
      let formFile = new FormData();
      formFile.append("file", e.raw);
      await importCrmLine(formFile).then(e => {
        console.log("导入成功:" + e)
      }, err => {
        console.log("导入出错:" + err);
      })
      this.$message.success('线索导入成功!');
      this.showImportDialog = false
      this.searchData()

    },
    //选中文件时
    exceedFile(e) {
      console.log("exceedFile时:", e);
    },
    //移除文件时
    handleRemove(e) {
      console.log("removeFile时:", e);
    },
    //检索 教练的姓名
    selectCoach(e) {
      listCoach(e).then(res => {
        if (res.code === 200) {
          this.coachList = res.data
        } else {
          this.$message.error('')
        }
      })
    },
    //分配线索 给教练操作前点击
    editLineCoach(row) {
      this.showAllocationCrmLineDialog = true
      //清空所选线索行
      this.editLineCoachForm.crmLines = [];
      this.editLineCoachForm.crmLines.push(row);
    },
    //执行线索的分配
    doEditLineCoach() {
      console.log("传入行的参数:", this.editLineCoachForm);
      //执行线索分配接口调用
      allocationLine(this.editLineCoachForm).then(res => {
        this.showAllocationCrmLineDialog = false
        if (res.code === 200) {
          this.$message.success('分配成功')
          //重新加载数据
          this.searchData()
        } else {
          this.$message.error('修改失败')
        }
      });
    },
  },
  //当前组件的dom和vm实例初始化 完毕后
  created() {
    this.getInitData()
  }
}
</script>

<style scoped>
.inPut {
  margin: 0 10px;
  width: 260px
}
.el-table__cell {
  position: static !important;
}
.mx-1 {
  margin: 5px;
}

.mr10 {
  margin-right: 10px;
}
</style>